var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
import AsyncStatus from "../../utils/async";
import updateContactPersonName from "../../api/mutations/updateContactPersonName";
import NotificationsMixin from "../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
import { rethrowPossibleApolloErrorAsUserMessage } from "../../api/apolloErrorToUserMessage";
export default mixins(NotificationsMixin).extend({
    props: {
        agent: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        agent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment UpdateContactPersonNameForm_agent on Agent {\n        id\n        contactPersonName\n      }\n    "], ["\n      fragment UpdateContactPersonNameForm_agent on Agent {\n        id\n        contactPersonName\n      }\n    "]))),
    },
    data: function () {
        return {
            form: {
                contactPersonName: this.agent.contactPersonName,
            },
            status: new AsyncStatus(),
        };
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.$validator.validate().then(function (valid) {
                if (!valid) {
                    return;
                }
                _this.status
                    .run(_this.$apollo
                    .mutate(updateContactPersonName(_this.agent.id, _this.form))
                    .catch(rethrowPossibleApolloErrorAsUserMessage))
                    .then(function () { return _this.displaySuccessMessage(_this.$t("Name updated")); });
            });
        },
    },
});
var templateObject_1;
