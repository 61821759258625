var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
export default (function (studentId, input) { return ({
    mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation updateAgencyNameMutation($input: UpdateAgencyNameInput!) {\n      updateAgencyName(input: $input) {\n        viewerAgent {\n          id\n          agencyName\n        }\n      }\n    }\n  "], ["\n    mutation updateAgencyNameMutation($input: UpdateAgencyNameInput!) {\n      updateAgencyName(input: $input) {\n        viewerAgent {\n          id\n          agencyName\n        }\n      }\n    }\n  "]))),
    variables: { input: input },
    optimisticResponse: {
        updateAgencyName: {
            __typename: "UpdateAgencyNameOutput",
            viewerAgent: {
                __typename: "Agent",
                id: studentId,
                agencyName: input.agencyName,
            },
        },
    },
}); });
var templateObject_1;
