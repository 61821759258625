var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import UpdateAgencyNameForm from "./UpdateAgencyNameForm.vue";
import UpdateContactPersonNameForm from "./UpdateContactPersonNameForm.vue";
import UpdateEmailAddressForm from "./UpdateEmailAddressForm.vue";
import UpdatePhoneNumberForm from "./UpdatePhoneNumberForm.vue";
import UpdatePasswordForm from "../ui/form/UpdatePasswordForm.vue";
export default Vue.extend({
    components: {
        UpdateAgencyNameForm: UpdateAgencyNameForm,
        UpdateContactPersonNameForm: UpdateContactPersonNameForm,
        UpdateEmailAddressForm: UpdateEmailAddressForm,
        UpdatePhoneNumberForm: UpdatePhoneNumberForm,
        UpdatePasswordForm: UpdatePasswordForm,
    },
    apollo: {
        viewerAgent: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        {\n          viewerAgent {\n            id\n            ...UpdateAgencyNameForm_agent\n            ...UpdateContactPersonNameForm_agent\n            ...UpdateEmailAddressForm_agent\n            ...UpdatePhoneNumberForm_agent\n          }\n        }\n        ", "\n        ", "\n        ", "\n        ", "\n      "], ["\n        {\n          viewerAgent {\n            id\n            ...UpdateAgencyNameForm_agent\n            ...UpdateContactPersonNameForm_agent\n            ...UpdateEmailAddressForm_agent\n            ...UpdatePhoneNumberForm_agent\n          }\n        }\n        ", "\n        ", "\n        ", "\n        ", "\n      "])), UpdateAgencyNameForm.options.fragments.agent, UpdateContactPersonNameForm.options.fragments.agent, UpdateEmailAddressForm.options.fragments.agent, UpdatePhoneNumberForm.options.fragments.agent),
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Account").toString(),
        };
    },
});
var templateObject_1;
